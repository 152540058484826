import '../scss/style.scss';

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import '@babel/polyfill';


// ============================================
// モジュール読み込み
// ============================================
import { fixedHeader ,drawer } from './lib/header';
import { ScrollHintFunc } from './lib/ScrollHint';
import { switchViewportFunc } from './lib/switchViewport';
//import { pageTop } from './lib/PageTop';
//import { tabFunc } from './lib/Tab';
import { accordionFunc } from './lib/Accordion';
import { addAutoFunc, branchSelect, branchSelectOther, numKeyFunc } from './lib/Form';
import { Slider, slideRow, slideFlow, spSlider } from './lib/Slider';
import { mouseStalker } from './lib/Cursor';
//import { HeadroomFunc } from './lib/Headroom';
import { Inview } from './lib/Scroll';
import { HeroFade } from './lib/Hero';


// ============================================
// 実行
// ============================================
window.addEventListener("DOMContentLoaded", () => {
	switchViewportFunc();
	fixedHeader();
	drawer();
	ScrollHintFunc();
	//pageTop();
	//tabFunc();
	accordionFunc();
	addAutoFunc();
	numKeyFunc();
	//HeadroomFunc();
	Slider();
	slideRow();
	mouseStalker();
	slideFlow();
	Inview();
	branchSelect();
	branchSelectOther();
});
window.addEventListener("load", () => {
	//全部読み込み完了でbodyにクラス付与
	document.body.classList.add('is-loaded');
	HeroFade();
});