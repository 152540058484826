// index Hero
// ==========================
function HeroFade() {

	const mvArea = document.querySelectorAll('.js-mv-fadein');
	const fadeItem = document.querySelectorAll('.js-mv-fadein__item');

	if(mvArea){
		window.addEventListener('load', () => {
			mvArea.classList.add('is-active');
		});
	}

}

// ============================================
// エクスポート
// ============================================
export{ HeroFade };