// ============================================
// gsap scrollTrigger
// ============================================
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Inview() {
	const fadeItems = gsap.utils.toArray('.js-inview');
	fadeItems.forEach((fadeItem) => {
		ScrollTrigger.create({
			trigger: fadeItem, //アニメーションが始まるトリガーとなる要素
			start: 'top 75%',
			end: 'bottom 75%',
			toggleClass: {
				targets: fadeItem,
				className: "is-active"
			},
			once: true
		});
	});
}

// ============================================
// export
// ============================================
export { Inview };