// ============================================
// フォーム
// ============================================

//住所自動入力
// ==========================
function addAutoFunc() {
  const formTag = document.querySelector('form');
  const zipInput = document.querySelector('[name="zip"]');
  const addInput = document.querySelector('[name="add"]');
  if (zipInput) {
    formTag.classList.add('h-adr');
    formTag.insertAdjacentHTML('afterbegin', '<input type="hidden" class="p-country-name" value="Japan">');
    zipInput.classList.add('p-postal-code');
    addInput.classList.add('p-region', 'p-locality', 'p-street-address', 'p-extended-address');
  }

  new YubinBango.MicroformatDom();
}

//数字キーボード
// ==========================
function numKeyFunc() {
  const numKeyAry = document.querySelectorAll('.js-num-input');
  if (numKeyAry) {
    numKeyAry.forEach((el) => {
      el.setAttribute('inputmode', 'tel');
    });
  }
}

//フォームの条件分岐（input[name="family"]）
// ==========================
function branchSelect() {
  //ラジオfamilyの値を取得する
  const familyValue = document.querySelectorAll('input[name="family"]');
  const familyInput = document.querySelector('input[name="familyNumber"]');

  if (familyValue.length !== 0 || familyInput) {
    function addDisabled() {
      familyInput.setAttribute('disabled', true);
    }
    addDisabled();

    familyValue.forEach((familyRadio) => {
      familyRadio.addEventListener('change', () => {
        console.log(familyRadio.value);
        if (familyRadio.value === 'はい') {
          //インプットにdisable属性をはずす
          familyInput.removeAttribute('disabled');
        } else {
          //インプットにdisable属性をつける
          addDisabled();
        }
      });
    });
  }
}

//フォームの条件分岐（input[name="contact"]）
// ==========================
function branchSelectOther() {
  //ラジオcontactの値を取得する
  const contactValue = document.querySelectorAll('input[name="contact"]');
  const contactInput = document.querySelector('input[name="contactOther"]');

  if (contactValue.length !== 0 || contactInput) {
    function addDisabled() {
      contactInput.setAttribute('disabled', true);
    }
    addDisabled();

    contactValue.forEach((contactRadio) => {
      contactRadio.addEventListener('change', () => {
        console.log(contactRadio.value);
        if (contactRadio.value === 'その他') {
          //インプットにdisable属性をはずす
          contactInput.removeAttribute('disabled');
        } else {
          //インプットにdisable属性をつける
          addDisabled();
        }
      });
    });
  }
}



export { addAutoFunc, numKeyFunc, branchSelect, branchSelectOther };
