import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
// ============================================
// case
// ============================================

function Slider() {
  const swiper = new Swiper('.js-slider.swiper', {
    // direction: 'vertical',
    loop: true,
		speed: 1000,
    autoplay: {
      delay: 3000,
    },
    navigation: {
      nextEl: '.js-slider__next',
      prevEl: '.js-slider__prev',
    },
    slidesPerView: 1.5,
    spaceBetween: 16,
    breakpoints: {
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 40,
      },
    },
  });
}

// ============================================
// 2段のとき
// ============================================
function slideRow() {
  const swiperRow = new Swiper('.js-slideRow.swiper', {
    slidesPerView: 1.5,
    spaceBetween: 16,
    loop: true,
    autoplay: {
      delay: 6000,
    },
    // grid: {
    // 	fill: 'row',
    // 	rows: 2,
    // },
    breakpoints: {
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 40,
        // grid: {
        // 	rows: 2,
        // },
      },
    },
    navigation: {
      nextEl: '.js-slider__next',
      prevEl: '.js-slider__prev',
    },
  });
}

// ============================================
// flow
// ============================================
function slideFlow() {
  let swiper;

  // 有効化時のオプションを記述
  function initializeSwiper() {
    swiper = new Swiper('.js-slider-flow.swiper', {
      spaceBetween: 60,
      slidesPerView: 2.5,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.js-slider__next',
        prevEl: '.js-slider__prev',
      },
    });
  }
  // 無効化する用の記述
  function destroySwiper() {
    if (swiper) {
      swiper.destroy();
      swiper = undefined;
    }
  }
  // if分を用いて横幅に応じて関数を実行
  function handleResize() {
    let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (w >= 768) {
      if (!swiper) {
        initializeSwiper();
      }
    } else {
      destroySwiper();
    }
  }
  // 初回実行
  handleResize();
  window.addEventListener('resize', handleResize);
}

// ============================================
// SP時スライダー
// ============================================
function spSlider() {
  let swiper;

  // 有効化時のオプションを記述
  function initializeSwiper() {
    swiper = new Swiper('.js-slider-sp.swiper', {
      slidesPerView: 1.5,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.js-slider__next',
        prevEl: '.js-slider__prev',
      },
    });
  }
  // 無効化する用の記述
  function destroySwiper() {
    if (swiper) {
      swiper.destroy();
      swiper = undefined;
    }
  }
  // if分を用いて横幅に応じて関数を実行
  function handleResize() {
    let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (w <= 768) {
      if (!swiper) {
        initializeSwiper();
      }
    } else {
      destroySwiper();
    }
  }

  // 初回実行
  handleResize();
  window.addEventListener('resize', handleResize);
}

// ============================================
// export
// ============================================
export { Slider, slideRow, slideFlow, spSlider };
